import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import isFunction from 'lodash.isfunction';

import * as CustomPropTypes from 'src/utils/custom-prop-types';
import MediaQuery from 'src/components/MediaQuery';
import KeyboardistNoSSR from 'src/components/KeyboardistNoSSR';
import Portal, { AbsolutePortal } from 'src/components/Portal';

import DropdownContent from './DropdownContent';
import DropdownHeader from './DropdownHeader';
import DropdownGroup from './DropdownGroup';
import DropdownItem from './DropdownItem';
import DropdownMenuOverlay from '../styles/DropdownMenuOverlay';
import DropdownMenuPanel, {
  SwipeableDropdownMenuPanel,
} from '../styles/DropdownMenuPanel';
import DropdownSeparator from './DropdownSeparator';

import DropdownContext from './DropdownContext';

class DropdownMenu extends Component {
  static contextType = DropdownContext;

  handleClickOutside = evt => {
    const { closeMenu } = this.context;
    closeMenu();
    evt.stopPropagation();
  };

  position() {
    const { horizontalOffset, verticalOffset } = this.props;
    const { posX, posY } = this.context;
    const yProperty = posY === 'top' ? 'bottom' : 'top';
    const xProperty = posX === 'left' ? 'right' : 'left';

    return {
      [yProperty]: `calc(100% + ${verticalOffset})`,
      [xProperty]: `calc(0px + ${horizontalOffset})`,
    };
  }

  render() {
    const { closeMenu } = this.context;
    const { width } = this.props;
    let { children } = this.props;

    if (isFunction(children)) {
      children = children(this.context);
    }

    return (
      <MediaQuery query={{ minWidth: 0 }}>
        {matches =>
          matches ? (
            <DropdownMenuPanel width={width} {...this.position()}>
              <KeyboardistNoSSR bindings={{ Escape: closeMenu }} />
              {children}
            </DropdownMenuPanel>
          ) : (
            <Portal>
              <SwipeableDropdownMenuPanel
                onSwipedDown={closeMenu}
                {...this.position()}
              >
                {children}
              </SwipeableDropdownMenuPanel>
              <DropdownMenuOverlay onClick={closeMenu} role="button" />
            </Portal>
          )
        }
      </MediaQuery>
    );
  }
}

DropdownMenu.propTypes = {
  children: CustomPropTypes.oneOrMoreOfType([PropTypes.element, PropTypes.node])
    .isRequired,
  horizontalOffset: PropTypes.string,
  verticalOffset: PropTypes.string,
  width: PropTypes.string,
};

DropdownMenu.defaultProps = {
  horizontalOffset: '0px',
  verticalOffset: '0px',
  width: 'auto',
};

const DropdownMenuWithClickOutside = onClickOutside(DropdownMenu);

DropdownMenuWithClickOutside.displayName = 'DropdownMenuWithClickOutside';
DropdownMenuWithClickOutside.defaultProps = {
  eventTypes: ['click', 'touchstart'],
};

const DropdownMenuWithWrapPortal = ({ wrapPortal, ...props }) =>
  wrapPortal ? (
    <AbsolutePortal>
      <DropdownMenuWithClickOutside {...props} />
    </AbsolutePortal>
  ) : (
    <DropdownMenuWithClickOutside {...props} />
  );

DropdownMenuWithWrapPortal.propTypes = {
  wrapPortal: PropTypes.bool,
};

DropdownMenuWithWrapPortal.defaultProps = {
  wrapPortal: false,
};

DropdownMenuWithWrapPortal.Content = DropdownContent;
DropdownMenuWithWrapPortal.Group = DropdownGroup;
DropdownMenuWithWrapPortal.Header = DropdownHeader;
DropdownMenuWithWrapPortal.Item = DropdownItem;
DropdownMenuWithWrapPortal.Separator = DropdownSeparator;

export default DropdownMenuWithWrapPortal;
