import { warning } from 'src/utils/deprecation-log';
import { textStyle } from './utils';

export const bodyTextStyle = (props, customValues) => {
  warning(
    'bodyTextStyle',
    '`bodyTextStyle` mixin has been deprecated. Use `styled-system` mixins instead.',
  );
  return textStyle({
    fontFamily: props.theme.fontFamilies.sansSerif,
    fontSize: props.theme.fontSizeNames.body.normal,
    fontWeight: props.theme.fontWeights.light,
    letterSpacing: '1px',
    lineHeight: props.theme.lineHeights[5],
    ...customValues,
  });
};

export const printBodyTextStyle = (props, customValues) => {
  warning(
    'printBodyTextStyle',
    '`printBodyTextStyle` mixin has been deprecated. Use `styled-system` mixins instead.',
  );
  return bodyTextStyle(props, {
    fontSize: props.theme.fontSizeNames.print.body.normal,
    letterSpacing: props.theme.letterSpacings[2],
    lineHeight: props.theme.lineHeights[0] + 1,
    ...customValues,
  });
};

export const bodySerifTextStyle = (props, customValues) => {
  warning(
    'bodySerifTextStyle',
    '`bodySerifTextStyle` mixin has been deprecated. Use `styled-system` mixins instead.',
  );
  return textStyle({
    fontFamily: props.theme.fontFamilies.serif,
    fontSize: props.theme.fontSizeNames.body.serif,
    fontStyle: props.italic ? 'italic' : 'normal',
    fontWeight: props.bold
      ? props.theme.fontWeights.bold
      : props.theme.fontWeights.light,
    letterSpacing: '.6px',
    lineHeight: props.theme.lineHeights[5],
    ...customValues,
  });
};

export const printBodySerifTextStyle = (props, customValues) => {
  warning(
    'printBodySerifTextStyle',
    '`printBodySerifTextStyle` mixin has been deprecated. Use `styled-system` mixins instead.',
  );
  return bodySerifTextStyle(props, {
    fontSize: props.theme.fontSizeNames.print.body.serif,
    letterSpacing: props.theme.letterSpacings[0],
    lineHeight: props.theme.lineHeights[0] + 1,
    ...customValues,
  });
};
