/* stylelint-disable no-duplicate-selectors */
import styled from 'styled-components';

import { variants } from 'src/utils/theming';

const kindPropertyVariants = variants('kind', {
  page: `
    align-items: center;
    padding: 35px 0 45px
  `,
  section: `
    align-items: left;
    margin-bottom: 28px;
  `,
});

const Header = styled.div`
  display: flex;
  flex-direction: column;
  ${kindPropertyVariants};
`;

export default Header;
