import styled from 'styled-components';
import PropTypes from 'prop-types';

import Text from './Text';

const Heading = styled(Text).attrs(props => ({
  textStyle: `heading.${props.textSize}`,
}))``;

Heading.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']),
  textSize: PropTypes.oneOf(['xxlarge', 'xlarge', 'large', 'regular', 'small']),
};

Heading.defaultProps = {
  as: 'h1',
  color: 'black',
  fontFamily: 'sansSerif',
  fontWeight: 'light',
  textSize: 'regular',
};

Heading.displayName = 'Heading';

export default Heading;
