import styled from 'styled-components';
import tag from 'clean-tag';
import { CheckBox, CheckBoxOutlineBlank } from 'styled-icons/material';
import themeGet from '@styled-system/theme-get';
import { background, space } from 'styled-system';

import { layout, typography } from 'src/mixins/styled-system';
import extendTagBlacklist from 'src/utils/extend-tag-blacklist';
import buildDataUriSvg from 'src/utils/build-data-uri-svg';

const Checkbox = styled(tag.input).attrs({
  type: 'checkbox',
})`
  ${background};
  ${space};
  ${layout};
  appearance: none;
  background-image: url(${props =>
    buildDataUriSvg(props.uncheckedSvg, {
      color: themeGet(`colors.${props.uncheckedColor}`)(props),
    })});
  cursor: pointer;

  &:disabled {
    background-image: url(${props =>
      buildDataUriSvg(props.disabledSvg || props.uncheckedSvg, {
        color: themeGet(`colors.${props.disabledColor}`)(props),
      })});
    cursor: not-allowed;
  }

  &:checked {
    background-image: url(${props =>
      buildDataUriSvg(props.checkedSvg, {
        color: themeGet(`colors.${props.checkedColor}`)(props),
      })});

    :disabled {
      background-image: url(${props =>
        buildDataUriSvg(props.disabledSvg || props.checkedSvg, {
          color: themeGet(`colors.${props.disabledColor}`)(props),
        })});
    }
  }
`;

Checkbox.propTypes = {};

// order matters for overriding certain props (e.g. font weight)
Checkbox.defaultProps = {
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100%',
  blacklist: extendTagBlacklist([
    ...typography.blacklist,
    'checkedColor',
    'checkedSvg',
    'disabledColor',
    'disabledSvg',
    'uncheckedColor',
    'uncheckedSvg',
  ]),
  checkedColor: 'primary',
  checkedSvg: CheckBox,
  disabledColor: 'grey.400',
  disabledSvg: null,
  height: '24px',
  m: 0,
  uncheckedColor: 'grey.600',
  uncheckedSvg: CheckBoxOutlineBlank,
  width: '24px',
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;
