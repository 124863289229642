import { warning } from 'src/utils/deprecation-log';
import { textStyle } from './utils';

export const headingOneTextStyle = (props, customValues) => {
  warning(
    'headingOneTextStyle',
    '`headingOneTextStyle` mixins have been deprecated. Use `styled-system` mixins instead.',
  );
  return textStyle({
    fontFamily: props.theme.fontFamilies.sansSerif,
    fontSize: props.theme.fontSizeNames.h1,
    fontWeight: props.theme.fontWeights.light,
    letterSpacing: props.theme.letterSpacings[4],
    lineHeight: props.theme.lineHeights[5],
    ...customValues,
  });
};

export const printHeadingOneTextStyle = (props, customValues) => {
  warning(
    'printHeadingOneTextStyle',
    '`printHeadingOneTextStyle` mixins have been deprecated. Use `styled-system` mixins instead.',
  );
  return headingOneTextStyle(props, {
    fontSize: props.theme.fontSizeNames.print.h1,
    letterSpacing: props.theme.letterSpacings[1],
    lineHeight: props.theme.lineHeights[3],
    ...customValues,
  });
};

export const headingTwoTextStyle = (props, customValues) => {
  warning(
    'headingTwoTextStyle',
    '`headingTwoTextStyle` mixins have been deprecated. Use `styled-system` mixins instead.',
  );
  return headingOneTextStyle(props, {
    fontSize: props.theme.fontSizeNames.h2,
    letterSpacing: props.theme.letterSpacings[5],
    lineHeight: props.theme.lineHeights[4],
    ...customValues,
  });
};

export const printHeadingTwoTextStyle = (props, customValues) => {
  warning(
    'printHeadingTwoTextStyle',
    '`printHeadingTwoTextStyle` mixins have been deprecated. Use `styled-system` mixins instead.',
  );
  return headingTwoTextStyle(props, {
    fontSize: props.theme.fontSizeNames.print.h2,
    lineHeight: props.theme.lineHeights[1],
    ...customValues,
  });
};

export const headingThreeTextStyle = (props, customValues) => {
  warning(
    'headingThreeTextStyle',
    '`headingThreeTextStyle` mixins have been deprecated. Use `styled-system` mixins instead.',
  );
  return headingOneTextStyle(props, {
    fontSize: props.theme.fontSizeNames.h3,
    fontWeight: 'normal',
    letterSpacing: props.theme.letterSpacings[7],
    lineHeight: props.theme.lineHeights[2],
    textTransform: 'uppercase',
    ...customValues,
  });
};

export const printHeadingThreeTextStyle = (props, customValues) => {
  warning(
    'printHeadingThreeTextStyle',
    '`printHeadingThreeTextStyle` mixins have been deprecated. Use `styled-system` mixins instead.',
  );
  return headingThreeTextStyle(props, {
    fontSize: props.theme.fontSizeNames.print.h3,
    letterSpacing: props.theme.letterSpacings[4],
    lineHeight: props.theme.lineHeights[0],
    ...customValues,
  });
};

export const headingFourTextStyle = (props, customValues) => {
  warning(
    'headingFourTextStyle',
    '`headingFourTextStyle` mixins have been deprecated. Use `styled-system` mixins instead.',
  );
  return headingOneTextStyle(props, {
    fontSize: props.theme.fontSizeNames.h4,
    fontWeight: 'normal',
    letterSpacing: props.theme.letterSpacings[5],
    lineHeight: props.theme.lineHeights[2],
    ...customValues,
  });
};
