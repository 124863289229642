import styled from 'styled-components';
import { transitions, transparentize } from 'polished';
import themeGet from '@styled-system/theme-get';

import { common, layout, location, typography } from 'src/mixins/styled-system';
import transition from 'src/mixins/css-transitions';
import extendTagBlacklist from 'src/utils/extend-tag-blacklist';

const animationSettings = property => ({
  duration: '200ms',
  property,
});

const Input = styled.input`
  ${common};
  ${layout};
  ${location};
  ${typography};
  appearance: none;
  background-color: ${themeGet('colors.input.background.default')};
  border: 1px solid ${themeGet('colors.input.background.default')};
  border-radius: 2px;
  color: ${themeGet('colors.input.text.default')};
  ${transitions(
    transition(animationSettings('color')),
    transition(animationSettings('background-color')),
    transition(animationSettings('border-color')),
  )};

  &::placeholder {
    color: ${themeGet('colors.input.text.placeholder')};
  }

  &:hover {
    border-color: ${themeGet('colors.grey.300')};
  }

  &:focus {
    background-color: ${themeGet('colors.input.background.focus')};
    border-color: ${themeGet('colors.primary')};
    box-shadow: 0 0 6px 0
      ${props => transparentize(0.8, themeGet('colors.primary')(props))};
  }

  &:disabled {
    background-color: ${themeGet('colors.input.background.disabled')};
    border-color: ${themeGet('colors.grey.400')};
    color: ${themeGet('colors.input.text.disabled')};
    cursor: not-allowed;
  }
`;

Input.defaultProps = {
  blacklist: extendTagBlacklist(typography.blacklist),
  color: 'black',
  fontFamily: 'sansSerif',
  m: 0,
  px: 4,
  py: 2,
  textStyle: 'body.regular',
  width: undefined,
};

Input.displayName = 'Input';

export default Input;
