import styled, { css } from 'styled-components';

import iconGlyphStyle from 'src/mixins/icon-glyph-style';

const labelSize = 30;

const iconLeftStyle = css`
  &::before {
    content: '${props => props.iconLeft}';
    left: ${props => props.theme.space[4]}px;
  }

  input,
  select,
  textarea {
    padding-left: ${props => props.theme.space[6] + 20}px;
  }
`;

const iconRightStyle = css`
  &::after {
    content: '${props => props.iconRight}';
    right: ${props => props.theme.space[4]}px;
  }

  input,
  select,
  textarea {
    padding-right: ${props => props.theme.space[6] + 20}px;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  ::before,
  ::after {
    ${iconGlyphStyle};
    color: ${props => props.theme.colors.gray['600']};
    font-size: 20px;
    height: 20px;
    pointer-events: none;
    position: absolute;
    top: ${({ withLabel }) => (withLabel ? labelSize + 9 : 9)}px;
    width: 20px;
    z-index: 50;
  }

  input,
  select,
  textarea {
    padding: ${({ theme: { space } }) => `${space[2]}px ${space[4]}px`};
  }

  ${props => props.iconLeft && iconLeftStyle};
  ${props => props.iconRight && iconRightStyle};
`;

export default FieldWrapper;
