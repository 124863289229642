import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const getPageOffset = () => ({
  x:
    window.pageXOffset !== undefined
      ? window.pageXOffset
      : (document.documentElement || document.body.parentNode || document.body)
          .scrollLeft,
  y:
    window.pageYOffset !== undefined
      ? window.pageYOffset
      : (document.documentElement || document.body.parentNode || document.body)
          .scrollTop,
});

function AbsolutePortal({ children, style }) {
  const isDocumentDefined = typeof document !== 'undefined';
  const parent = isDocumentDefined ? document.body : null;
  const [container] = useState(() => {
    if (isDocumentDefined) {
      const portal = document.createElement('div');
      portal.id = AbsolutePortal.className;
      return portal;
    }

    // ssr
    return null;
  });
  const referenceEl = useRef();

  // append portal container to parent (document.body or other)
  useEffect(() => {
    if (!container || !parent) return null;

    parent.appendChild(container);

    return () => {
      parent.removeChild(container);
    };
  }, [container, parent]);

  useEffect(() => {
    const rect = referenceEl.current.getBoundingClientRect();
    const pageOffset = getPageOffset();
    const top = `${pageOffset.y + rect.top}px`;
    const right = `${window.innerWidth - rect.right - pageOffset.x}px`;
    const left = `${pageOffset.x + rect.left}px`;
    container.style.cssText = Object.entries({
      position: 'absolute',
      top,
      right,
      left,
    }).reduce(
      (s, [propName, propValue]) => `${s}${propName}:${propValue};`,
      container.style.cssText || '',
    );
  }, [container, parent]);

  useEffect(() => {
    if (style) {
      container.style.cssText = Object.entries(style).reduce(
        (s, [propName, propValue]) => `${s}${propName}:${propValue};`,
        container.style.cssText || '',
      );
    }
  }, [container, style]);

  if (container) {
    const portal = createPortal(children, container);
    return <div ref={referenceEl}>{portal}</div>;
  }

  // ssr
  return null;
}

AbsolutePortal.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.oneOfType([PropTypes.object]),
};

AbsolutePortal.defaultProps = {
  style: null,
};

AbsolutePortal.className = 'prisma__portal';

export default AbsolutePortal;
