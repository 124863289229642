import styled from 'styled-components';

import HintMessage from 'src/components/HintMessage/styles/HintText';

const RadioFieldContainer = styled.div`
  display: inline-block;
  margin: 0 0 ${props => props.theme.space[4]}px;
  position: relative;
  text-align: left;
  width: 100%;

  ${HintMessage /* sc-sel */} {
    margin-top: ${props => props.theme.space[1]}px;
    margin-left: ${props => 20 + props.theme.space[4]}px;
  }
`;

export default RadioFieldContainer;
