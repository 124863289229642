// 36em - 576px
// 48em - 768px
// 62em - 992px
// 75em - 1.200px
// 88em - 1.408px
export const breakpoints = ['36em', '48em', '62em', '75em', '88em'];
export const deviceBreakpoints = {
  mobile: breakpoints[0],
  phablet: breakpoints[1],
  tablet: breakpoints[2],
  desktop: breakpoints[3],
  desktopHd: breakpoints[4],
};

// typography
export const baseFontSizes = 16;
export const fontSizes = [
  baseFontSizes * 0.75, // 0 – 12px
  baseFontSizes * 0.875, // 1 –  14px
  baseFontSizes * 1, // 2 –  16px (base)
  baseFontSizes * 1.125, // 3 –  18px
  baseFontSizes * 1.25, // 4 –  20px
  baseFontSizes * 1.5, // 5 –  24px
  baseFontSizes * 2.25, // 6 –  36px
  baseFontSizes * 3.25, // 7 –  52px
  baseFontSizes * 4.5, // 8 –  72px
];

export const fonts = {
  icons: 'Material Icons',
  sansSerif: 'CalibreWeb, sans-serif',
  serif: 'ITC-Charter, serif',
};

export const lineHeights = [
  '1em', // 0
  '1.1em', // 1
  '1.2em', // 2
  '1.3em', // 3
  '1.4em', // 4
  '1.5em', // 5
  '1.7em', // 6
];

export const letterSpacings = [
  '-0.015em', // 0
  '-0.01em', // 1
  '0em', // 2 – normal
  '0.015em', // 3
  '0.02em', // 4
  '0.025em', // 5
  '0.03em', // 6
  '0.035em', // 7
  '0.045em', // 8
  '0.055em', // 9
];

export const fontWeights = [
  300, // 0 – light
  400, // 1 – regular
  500, // 2 – strong
  700, // 3 – bold
];
fontWeights.light = 300;
fontWeights.regular = 400;
fontWeights.strong = 500;
fontWeights.bold = 700;

export const textTransforms = ['none', 'uppercase'];

export const textStyles = {
  heading: {
    xxlarge: {
      fontSize: fontSizes[8], // 72px
      lineHeight: lineHeights[6], // 1.7em
      letterSpacing: letterSpacings[0], // -0.015em
    },
    xlarge: {
      fontSize: fontSizes[7], // 52px
      lineHeight: lineHeights[1], // 1.1em
      letterSpacing: letterSpacings[1], // -0.01em
    },
    large: {
      fontSize: fontSizes[6], // 36px
      lineHeight: lineHeights[2], // 1.1em
      letterSpacing: letterSpacings[2], // 0em
    },
    regular: {
      fontSize: fontSizes[5], // 24px
      lineHeight: lineHeights[3], // 1.3em
      letterSpacing: letterSpacings[3], // 0.015em
    },
    small: {
      fontSize: fontSizes[2], // 16px
      lineHeight: lineHeights[4], // 1.4em
      letterSpacing: letterSpacings[6], // 0.03em
      fontWeight: fontWeights[1], // regular
    },
  },
  subheading: {
    regular: {
      fontSize: fontSizes[1], // 14px
      lineHeight: lineHeights[6], // 1.7em
      letterSpacing: letterSpacings[9], // 0.055em
      fontWeight: fontWeights[1], // regular
      textTransform: textTransforms[1], // uppercase
    },
  },
  body: {
    large: {
      fontSize: fontSizes[3], // 18px
      lineHeight: lineHeights[4], // 1.4em
      letterSpacing: letterSpacings[5], // 0.025em
    },
    regular: {
      fontSize: fontSizes[2], // 16px
      lineHeight: lineHeights[6], // 1.7em
      letterSpacing: letterSpacings[6], // 0.03em
      fontWeight: fontWeights[0], // light (default?)
    },
    small: {
      fontSize: fontSizes[1], // 16px
      lineHeight: lineHeights[4], // 1.4em
      letterSpacing: letterSpacings[7], // 0.035em
    },
    xsmall: {
      fontSize: fontSizes[0], // 12px
      lineHeight: lineHeights[3], // 1.3em
      letterSpacing: letterSpacings[8], // 0.045em
    },
  },
  action: {
    large: {
      fontSize: fontSizes[4], // 20px
      lineHeight: lineHeights[0], // 1em
      letterSpacing: letterSpacings[4], // 0.02em
      fontWeight: fontWeights[1], // regular
    },
    regular: {
      fontSize: fontSizes[2], // 16px
      lineHeight: lineHeights[0], // 1em
      letterSpacing: letterSpacings[6], // 0.03em
      fontWeight: fontWeights[1], // regular
    },
    small: {
      fontSize: fontSizes[1], // 14px
      lineHeight: lineHeights[0], // 1em
      letterSpacing: letterSpacings[7], // 0.035em
      fontWeight: fontWeights[1], // regular
    },
  },
};

// colors
export const colors = {
  black: '#000',
  purple: '#6800c3',
  violet: '#3f0076',
  red: '#da1616',
  orange: '#ff9f00',
  green: '#28a354',
  grey: {
    100: '#f3f3f3',
    200: '#ededed',
    300: '#d2d2d2',
    400: '#b5b5b5',
    600: '#7a7a7a',
    900: '#212121',
  },
  white: '#fff',
};

// semantic
colors.modes = {
  dark: {
    primary: colors.purple,
    secondary: colors.grey[600],
    info: colors.violet,
    success: colors.green,
    warning: colors.orange,
    danger: colors.red,

    background: colors.grey[900],
    border: colors.grey[200],
    divider: colors.grey[300],
    muted: colors.grey[400],

    button: {
      text: colors.white,
    },

    input: {
      text: {
        default: colors.white,
        placeholder: colors.grey[600],
        disabled: colors.grey[600],
      },
      background: {
        default: colors.grey[900],
        focus: colors.orange,
        disabled: colors.grey[900],
      },
    },
  },
  light: {
    primary: colors.purple,
    secondary: colors.grey[600],
    info: colors.violet,
    success: colors.green,
    warning: colors.orange,
    danger: colors.red,

    background: colors.grey[100],
    border: colors.grey[200],
    divider: colors.grey[300],
    muted: colors.grey[400],

    button: {
      text: colors.black,
    },

    input: {
      text: {
        default: colors.black,
        placeholder: colors.grey[600],
        disabled: colors.grey[600],
      },
      background: {
        default: colors.grey[100],
        focus: colors.white,
        disabled: colors.white,
      },
    },
  },
};

// spacing
export const baseSpacing = 4;
export const space = [
  baseSpacing * 0, // 0
  baseSpacing * 1, // 4
  baseSpacing * 2, // 8
  baseSpacing * 3, // 12
  baseSpacing * 4, // 16
  baseSpacing * 6, // 24
  baseSpacing * 8, // 32
  baseSpacing * 12, // 48
  baseSpacing * 16, // 64
  baseSpacing * 32, // 128
  baseSpacing * 64, // 256
  baseSpacing * 128, // 512
];

// extra
export const shadows = {
  xsmall: '0 1px 2px rgba(0, 0, 0, 0.1);',
  small: '0 2px 4px rgba(0, 0, 0, 0.1);', // topbar
  medium: '0 4px 10px rgba(0, 0, 0, 0.1);', // popover
  large: '0 0px 16px rgba(0, 0, 0, 0.1);', // sidebar
  xlarge: '0 2px 16px rgba(0, 0, 0, 0.1);', // modal
};

export const sizes = {
  icon: {
    xxxsmall: 4,
    xxsmall: 8,
    xsmall: 12,
    small: 16,
    medium: 20,
    large: 24,
    xlarge: 28,
    xxlarge: 32,
    xxxlarge: 40,
  },
};

export const radii = {
  none: 0,
  small: '2px',
  medium: '4px',
  large: '8px',
};

export const zIndices = {
  sidebar: 200,
  modal: {
    panel: 300,
    overlay: 299,
  },
  dropdown: {
    panel: 300,
    overlay: 299,
  },
  tooltip: 400,
};
