import PropTypes from 'prop-types';
import isFunction from 'lodash.isfunction';
import * as CustomPropTypes from 'src/utils/custom-prop-types';
import { variants } from 'src/utils/theming';

import { warning } from 'src/utils/deprecation-log';

const COLORS_MAP = {
  primary: ({ theme }) => theme.colors.primary,
  secondary: ({ theme }) => theme.colors.info,
  success: ({ theme }) => theme.colors.success,
  error: ({ theme }) => theme.colors.danger,
  warning: ({ theme }) => theme.colors.warning,
  black: ({ theme }) => theme.colors.black,
  white: ({ theme }) => theme.colors.white,
  muted: ({ theme }) => theme.colors.grey[600],
  inherit: 'inherit',
  transparent: 'transparent',
};
export const colorOptions = Object.keys(COLORS_MAP);

export const colorProperty = (props, propName = 'color') => {
  warning(
    'colorProperty',
    '`colorProperty` has been deprecated. Use `styled-system` mixins instead.',
  );

  const prop = props[propName];
  let color;

  if (isFunction(prop)) {
    color = prop(props);
  } else {
    color =
      variants('color', COLORS_MAP)({
        ...props,
        color: prop,
      }) ||
      prop ||
      'initial';
  }
  return color;
};

export const colorPropType = PropTypes.oneOfType([
  PropTypes.oneOf(colorOptions),
  PropTypes.func,
  CustomPropTypes.hexColor,
]);
