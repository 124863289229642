import { warning } from 'src/utils/deprecation-log';
import { textStyle } from './utils';

export const captionTextStyle = (props, customValues) => {
  warning(
    'captionTextStyle',
    '`captionTextStyle` mixin has been deprecated. Use `styled-system` mixins instead.',
  );
  return textStyle({
    fontFamily: props.theme.fontFamilies.sansSerif,
    fontSize: props.theme.fontSizeNames.caption.normal,
    fontWeight: props.bold
      ? props.theme.fontWeights.strong
      : props.theme.fontWeights.light,
    letterSpacing: '1px',
    lineHeight: props.theme.lineHeights[4],
    ...customValues,
  });
};

export const captionSmallTextStyle = (props, customValues) => {
  warning(
    'captionSmallTextStyle',
    '`captionSmallTextStyle` mixin has been deprecated. Use `styled-system` mixins instead.',
  );
  return captionTextStyle(props, {
    fontSize: props.theme.fontSizeNames.caption.small,
    letterSpacing: '.1em',
    lineHeight: props.theme.lineHeights[4],
    ...customValues,
  });
};
