/**
 * Based on
 * https://github.com/styled-components/styled-theming
 */
function getThemeValue(name, props, values) {
  const value = props.theme && props.theme[name];

  let themeValue;

  if (typeof value === 'function') {
    themeValue = value(values);
  } else {
    themeValue = values[value];
  }

  if (typeof themeValue === 'function') {
    return themeValue(props);
  }
  return themeValue;
}

function getVariantValue(name, props, values) {
  const value = props && props[name];

  let variantValue;

  if (typeof value === 'function') {
    variantValue = value(values);
  } else {
    variantValue = values[value];
  }

  if (typeof variantValue === 'function') {
    return variantValue(props);
  }
  return variantValue;
}

// varies according to a theme property
export function theming(name, values) {
  return props => getThemeValue(name, props, values);
}

// varies according to a theme property followed by a component prop
theming.variants = (name, prop, values) => props => {
  const variant = props[prop] && values[props[prop]];
  return variant && getThemeValue(name, props, variant);
};

// varies according to a component prop
export function variants(name, values) {
  return props => getVariantValue(name, props, values);
}

// varies according to theme's mode property
export function mode(values) {
  return theming('mode', values);
}

// varies according to theme's mode property and a component prop
mode.variants = (name, prop, values) => theming.variants('mode', prop, values);
