import React from 'react';
import PropTypes from 'prop-types';

import DescriptionMessage from 'src/components/HintMessage';

import CheckboxContainer from './styles/CheckboxContainer';
import CheckboxInput from './styles/CheckboxInput';
import CheckboxLabel from './styles/CheckboxLabel';

const CheckboxField = ({
  id,
  className,
  checked,
  disabled,
  error,
  description,
  label,
  name,
  onBlur,
  onChange,
  undetermined,
  rounded,
}) => (
  <CheckboxContainer id={id} className={className}>
    <CheckboxInput
      id={`${name}-input-checkbox`}
      name={name}
      checked={checked}
      disabled={disabled}
      onBlur={onBlur}
      onChange={onChange}
      rounded={rounded}
    />
    <CheckboxLabel
      htmlFor={`${name}-input-checkbox`}
      undetermined={undetermined}
      rounded={rounded}
    >
      {label}
    </CheckboxLabel>
    {description && <DescriptionMessage>{description}</DescriptionMessage>}
    {error && (
      <DescriptionMessage kind="error" size="small">
        {error}
      </DescriptionMessage>
    )}
  </CheckboxContainer>
);

CheckboxField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool,
  undetermined: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.node,
  description: PropTypes.node,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  rounded: PropTypes.bool,
};

CheckboxField.defaultProps = {
  id: null,
  className: null,
  checked: false,
  disabled: false,
  undetermined: false,
  error: null,
  description: null,
  onBlur: null,
  onChange: null,
  rounded: false,
};

export default CheckboxField;
