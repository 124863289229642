import styled from 'styled-components';
import Panel from 'src/components/Panel';

const PopoverContentStyled = styled(Panel).attrs({
  flexDirection: 'column',
  elevation: 'medium',
  size: 'custom',
})`
  left: ${props => props.theme.space[7]}px;
  min-width: ${props => props.width};
  padding: ${props => props.theme.space[4]}px;
  position: absolute;
  top: -${props => props.theme.space[2]}px;

  ::after {
    border: 5px solid;
    border-color: transparent;
    border-right-color: ${props => props.theme.colors.white};
    content: '';
    position: absolute;
    right: 100%;
    top: 15px;
  }
`;

export default PopoverContentStyled;
