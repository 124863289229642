import styled from 'styled-components';
import tag from 'clean-tag';
import extendTagBlacklist from 'src/utils/extend-tag-blacklist';

const displayProp = ({ inline, flex }) => {
  let display = '';

  if (inline) {
    display = display.concat('inline-');
  }

  if (flex) {
    display = display.concat('flex');
  } else {
    display = display.concat('block');
  }

  return display;
};

const TruncateText = styled(tag).attrs(({ children }) => ({
  blacklist: extendTagBlacklist('inline'),
  // Only sends title if parsing result is not equals an object parsed to string
  title: children.toString() !== '[object Object]' ? children : null,
}))`
  display: ${displayProp};
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
`;

TruncateText.defaultProps = {
  flex: false,
  inline: false,
};

export default TruncateText;
