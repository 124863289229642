import styled from 'styled-components';

import { bodyTextStyle } from 'src/mixins/text-styles';

import SelectFieldMenu from './SelectFieldMenu';

export const SelectFieldLabel = styled.label`
  ${bodyTextStyle};
  color: ${props => props.theme.colors.gray['600']};
  margin-bottom: 4px;
  margin-left: 4px;

  ${SelectFieldMenu /* sc-sel */}:focus ~ & {
    color: ${props => props.theme.colors.primary};
  }

  ${SelectFieldMenu /* sc-sel */}.error ~ & {
    color: ${props => props.theme.colors.danger};
  }

  ${SelectFieldMenu /* sc-sel */}:disabled ~ & {
    color: ${props => props.theme.colors.gray['600']};
  }
`;

export default SelectFieldLabel;
