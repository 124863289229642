import { css } from 'styled-components';
import { transparentize } from 'polished';

export const ARROW_SIZE = 5;

export const COLOR_SCHEMES_MAP = {
  black: props => props.theme.colors.white,
  error: props => props.theme.colors.white,
  white: props => props.theme.colors.text,
};

export const BG_COLOR_SCHEMES_MAP = {
  black: props => transparentize(0.4, props.theme.colors.black),
  error: props => transparentize(0.4, props.theme.colors.danger),
  white: props => props.theme.colors.white,
};

export const COLOR_SCHEME_OPTIONS = Object.keys(COLOR_SCHEMES_MAP);

export const SHIFT_MAP = {
  bottom: css`
    top: -5px;

    &::after {
      content: '';
      position: absolute;
      ${props => props.direction /* sc-prop */}: 100%;
      top: 10px;
    }
  `,
  center: ({ axis }) =>
    axis === 'vertical'
      ? css`
          left: 50%;
          transform: translateX(-50%);

          &::after {
            content: '';
            position: absolute;
            ${props => props.direction /* sc-prop */}: 100%;
            left: 50%;
            margin-left: -5px;
          }
        `
      : css`
          top: 50%;
          transform: translateY(-50%);

          &::after {
            content: '';
            position: absolute;
            ${props => props.direction /* sc-prop */}: 100%;
            top: 50%;
            margin-top: -5px;
          }
        `,
  left: css`
    right: 0;

    &::after {
      content: '';
      position: absolute;
      ${props => props.direction /* sc-prop */}: 100%;
      right: 15px;
    }
  `,
  right: css`
    left: 0;

    &::after {
      content: '';
      position: absolute;
      ${props => props.direction /* sc-prop */}: 100%;
      left: 15px;
    }
  `,
  top: css`
    bottom: -5px;

    &::after {
      content: '';
      position: absolute;
      ${props => props.direction /* sc-prop */}: 100%;
      bottom: 10px;
    }
  `,
};

export const DIRECTIONS_MAP = {
  top: css`
    bottom: calc(100% + ${ARROW_SIZE}px + ${props => props.offset}px);
  `,
  bottom: css`
    top: calc(100% + ${ARROW_SIZE}px + ${props => props.offset}px);
  `,
  right: css`
    left: calc(100% + ${ARROW_SIZE}px + ${props => props.offset}px);
  `,
  left: css`
    right: calc(100% + ${ARROW_SIZE}px + ${props => props.offset}px);
  `,
};

export const DIRECTION_OPTIONS = Object.keys(DIRECTIONS_MAP);
