import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import get from 'lodash.get';
import merge from 'lodash.merge';

import picterTheme from 'src/themes/picter';
import { oneOrMoreOfType } from 'src/utils/custom-prop-types';

export const getTheme = (theme, mode) =>
  merge({}, theme, {
    mode,
    colors: get(theme.colors.modes, mode, theme.colors),
  });

const ThemeProvider = ({ children, mode, theme }) => {
  let t = Array.isArray(theme) ? merge({}, ...theme) : theme;

  if (t.colors) {
    t = getTheme(t, mode);
  }

  return <StyledThemeProvider theme={t}>{children}</StyledThemeProvider>;
};

ThemeProvider.defaultProps = {
  children: null,
  mode: 'light',
  theme: picterTheme,
};

ThemeProvider.propTypes = {
  children: PropTypes.node,
  mode: PropTypes.string,
  theme: oneOrMoreOfType([PropTypes.object]),
};

export default ThemeProvider;
