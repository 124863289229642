import styled from 'styled-components';
import PropTypes from 'prop-types';

import Text from './Text';

const Action = styled(Text).attrs(props => ({
  textStyle: `action.${props.textSize}`,
}))``;

Action.propTypes = {
  as: PropTypes.oneOf(['p', 'span']),
  textSize: PropTypes.oneOf(['large', 'regular', 'small']),
};

Action.defaultProps = {
  as: 'span',
  color: 'black',
  fontFamily: 'sansSerif',
  fontWeight: 'light',
  textSize: 'regular',
};

Action.displayName = 'Action';

export default Action;
