export const textStyle = ({
  fontFamily,
  fontSize,
  fontStyle = 'normal',
  fontWeight = 'normal',
  letterSpacing,
  lineHeight,
  textTransform = 'none',
}) => `
  font-family: ${fontFamily};
  font-size: ${fontSize}px;
  font-style: ${fontStyle};
  font-weight: ${fontWeight};
  letter-spacing: ${letterSpacing};
  line-height: ${lineHeight};
  text-transform: ${textTransform};
`;
