import React, { useMemo } from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import Media from 'react-media';
import isString from 'lodash.isstring';

import { oneOrMoreOfType } from 'src/utils/custom-prop-types';

import { mediaQuery } from '../utils';

const MediaQuery = withTheme(({ query, type, breakpoint, theme, ...props }) => {
  const parsedQuery = useMemo(() => {
    if (type && query) {
      throw new Error(
        'Cannot use `type` and `query` props in the same `MediaQuery`.' +
          'Use either `type` with `breakpoint` or `query`.',
      );
    }

    if (type) {
      return Array.isArray(breakpoint)
        ? mediaQuery[type](...breakpoint)({ theme })
        : mediaQuery[type](breakpoint)({ theme });
    }

    return query;
  }, [query, type, breakpoint, theme]);

  return (
    <Media
      query={
        isString(parsedQuery)
          ? parsedQuery
          : Object.entries(parsedQuery).reduce((queries, [rule, bpoint]) => {
              queries[rule] = theme.breakpoints[bpoint]; // eslint-disable-line
              return queries;
            }, {})
      }
      {...props}
    />
  );
});

MediaQuery.propTypes = {
  breakpoint: oneOrMoreOfType(PropTypes.string),
  children: PropTypes.func,
  defaultMatches: PropTypes.bool,
  onChange: PropTypes.func,
  query: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  render: PropTypes.func,
  targetWindow: PropTypes.object, // eslint-disable-line
  type: PropTypes.oneOf(['lessThan', 'between', 'greaterThan']),
};

MediaQuery.displayName = 'MediaQuery';

export default MediaQuery;
