let devWarnings = null;

if (process.env.NODE_ENV !== 'production') {
  if (typeof window !== 'undefined' && typeof window.Set !== 'undefined') {
    devWarnings = new Set();
  }
}

// eslint-disable-next-line import/prefer-default-export
export function warning(key, ...args) {
  if (args && args.length > 0) {
    if (devWarnings && !devWarnings.has(key)) {
      devWarnings.add(key);
      console.warn(...args); // eslint-disable-line no-console
    }
  } else {
    console.warn(key); // eslint-disable-line no-console
  }
}
