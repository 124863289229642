import styled from 'styled-components';
import PropTypes from 'prop-types';

import Text from './Text';

const Body = styled(Text).attrs(props => ({
  textStyle: `body.${props.textSize}`,
}))``;

Body.propTypes = {
  as: PropTypes.oneOf(['p', 'span']),
  textSize: PropTypes.oneOf(['large', 'regular', 'small', 'xsmall']),
};

Body.defaultProps = {
  as: 'p',
  color: 'black',
  fontFamily: 'sansSerif',
  fontWeight: 'light',
  textSize: 'regular',
};

Body.displayName = 'Body';

export default Body;
