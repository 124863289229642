import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { oneOrMoreOfType } from 'src/utils/custom-prop-types';
import * as theme from 'src/theme';

import AppNormalize from 'src/globals/AppNormalize';
import ModernNormalize from 'src/globals/ModernNormalize';
import FontLoader from 'src/components/FontLoader';
import ThemeProvider from 'src/components/ThemeProvider';

const PrismaProvider = props => (
  <Fragment>
    <ModernNormalize />
    <AppNormalize />
    <FontLoader />
    <ThemeProvider {...props} />
  </Fragment>
);

PrismaProvider.defaultProps = {
  children: null,
  mode: 'light',
  theme,
};

PrismaProvider.propTypes = {
  children: PropTypes.node,
  mode: PropTypes.string,
  theme: oneOrMoreOfType([PropTypes.object]),
};

export default PrismaProvider;
