import styled from 'styled-components';
import { captionTextStyle } from 'src/mixins/text-styles';

import { colorSchemeVariants } from './variants';

const ToastContainer = styled.div`
  ${captionTextStyle};
  ${colorSchemeVariants};
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  padding: ${props => props.theme.space[4]}px ${props => props.theme.space[5]}px;
`;

ToastContainer.defaultProps = {
  colorScheme: 'black',
};

export default ToastContainer;
