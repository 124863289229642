import { variants } from 'src/utils/theming';
import {
  SIZES_MAP,
  COLOR_SCHEMES_MAP,
  NORMAL_COLOR_SCHEMES_MAP,
} from '../constants';

export const sizeVariants = variants('size', SIZES_MAP);

export const colorSchemeVariants = variants('colorScheme', COLOR_SCHEMES_MAP);

export const normalColorSchemeVariants = variants(
  'colorScheme',
  NORMAL_COLOR_SCHEMES_MAP,
);
