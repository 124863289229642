import styled from 'styled-components';
import PropTypes from 'prop-types';

import Text from './Text';

const Subheading = styled(Text).attrs(props => ({
  textStyle: `subheading.${props.textSize}`,
}))``;

Subheading.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']),
  textSize: PropTypes.oneOf(['regular']),
};

Subheading.defaultProps = {
  as: 'h2',
  color: 'black',
  fontFamily: 'sansSerif',
  fontWeight: 'light',
  textSize: 'regular',
};

Subheading.displayName = 'Subheading';

export default Subheading;
