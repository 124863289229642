import styled from 'styled-components';
import { transparentize } from 'polished';
import { variants } from 'src/utils/theming';

import ButtonBase from './ButtonBase';
import { colorSchemeVariants } from './variants';

const ButtonOutlined = styled(ButtonBase)`
  background-color: transparent;
  border: ${props => `1px solid ${colorSchemeVariants(props)}`};
  color: ${colorSchemeVariants};
  padding: ${variants('size', {
    small: '6px 11px',
    medium: '9px 18px',
    large: '14px 23px',
  })};

  &:hover:not(:disabled) {
    background-color: ${props =>
      transparentize(0.9, colorSchemeVariants(props))};
  }

  &:active:not(:disabled) {
    background-color: ${props =>
      transparentize(0.8, colorSchemeVariants(props))};
  }
`;

export default ButtonOutlined;
