import React from 'react';
import PropTypes from 'prop-types';

import Portal from 'src/components/Portal';

import H2 from 'src/components/H2';
import KeyboardistNoSSR from 'src/components/KeyboardistNoSSR';
import P from 'src/components/P';
import Scrollbar from 'src/components/Scrollbar';

import ModalCloseButton from '../styles/ModalCloseButton';
import ModalContainer from '../styles/ModalContainer';
import ModalContent from '../styles/ModalContent';
import ModalDialog from '../styles/ModalDialog';
import ModalHeader from '../styles/ModalHeader';
import ModalOverlay from '../styles/ModalOverlay';
import { SIZE_OPTIONS } from '../constants';

const Modal = ({
  id,
  className,
  children,
  onClickClose,
  open,
  overlay,
  size,
  subtitle,
  title,
}) => (
  <Portal>
    {open && (
      <ModalContainer id={id} className={className}>
        {onClickClose && (
          <KeyboardistNoSSR
            bindings={{
              Escape: onClickClose,
            }}
          />
        )}
        {overlay && <ModalOverlay onClick={onClickClose} />}
        <ModalDialog size={size}>
          {onClickClose && <ModalCloseButton onClick={onClickClose} />}
          {title && (
            <ModalHeader>
              <H2>{title}</H2>
              {subtitle && <P>{subtitle}</P>}
            </ModalHeader>
          )}
          <Scrollbar maxHeight="70vh" distanceFromContent={40}>
            <ModalContent>{children}</ModalContent>
          </Scrollbar>
        </ModalDialog>
      </ModalContainer>
    )}
  </Portal>
);

Modal.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClickClose: PropTypes.func,
  open: PropTypes.bool,
  overlay: PropTypes.bool,
  size: PropTypes.oneOf(SIZE_OPTIONS),
  subtitle: PropTypes.node,
  title: PropTypes.node,
};

Modal.defaultProps = {
  id: null,
  className: null,
  onClickClose: null,
  open: false,
  overlay: true,
  size: 'medium',
  subtitle: null,
  title: null,
};

export default Modal;
