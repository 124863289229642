/**
 * STATUS
 *
 * IDLE - Fonts haven't been requested yet.
 * LOADING - All fonts have been request.
 * ACTIVE - All fonts have rendered.
 * INACTIVE - Browser does not support linked fonts or none of the fonts could be loaded.
 */
export const STATUS = {
  IDLE: '__WEBFONTS-IDLE__',
  LOADING: '__WEBFONTS-LOADING__',
  ACTIVE: '__WEBFONTS-ACTIVE__',
  INACTIVE: '__WEBFONTS-INACTIVE__',
};

export const CONFIG = {
  google: {
    families: ['Material Icons'],
  },
  custom: {
    families: ['CalibreWeb:n3,n4,n5', 'ITC-Charter:n4,n7,i4,i7'],
    urls: ['https://fonts.picter.com/fonts.css'],
  },
};
