import styled from 'styled-components';
import { textAlign } from 'styled-system';
import themeGet from '@styled-system/theme-get';

import { captionTextStyle } from 'src/mixins/text-styles';

import { ARROW_SIZE } from '../constants';
import {
  colorSchemeVariants,
  bgColorSchemeVariants,
  directionVariants,
  shiftVariants,
} from './variants';

const TooltipContent = styled.span`
  ${captionTextStyle};
  ${directionVariants};
  ${shiftVariants};
  ${textAlign};
  ${({ width }) => (width ? `width: ${width};` : 'white-space: nowrap;')};
  background-color: ${bgColorSchemeVariants};
  border-radius: 4px;
  color: ${colorSchemeVariants};
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  padding: ${props => props.theme.space[2]}px ${props => props.theme.space[4]}px;
  position: absolute;
  z-index: ${themeGet('zIndices.tooltip')};

  ::after {
    border-color: transparent;
    border-style: solid;
    border-width: ${ARROW_SIZE}px;
    ${props =>
      `border-${props.direction}-color: ${bgColorSchemeVariants(props)};`};
  }
`;

TooltipContent.defaultProps = {
  offset: 0,
};

export default TooltipContent;
