/**
 * Styled-system reference table
 * https://styled-system.com/table
 */
import {
  compose,
  color,
  space,
  system,
  textStyle,
  layout as ssLayout,
  position as ssPosition,
  typography as ssTypography,
} from 'styled-system';

// individual
export const textDecoration = system({
  textDecoration: true,
});
textDecoration.blacklist = 'textDecoration';

export const textTransform = system({
  textTransform: true,
});
textTransform.blacklist = 'textTransform';

export const textOverflow = system({
  textOverflow: true,
});
textOverflow.blacklist = 'textOverflow';

export const overflowWrap = system({
  overflowWrap: true,
});
overflowWrap.blacklist = 'overflowWrap';

export const whiteSpace = system({
  whiteSpace: true,
});
whiteSpace.blacklist = 'whiteSpace';

// groups
/**
 * color includes:
 * - color
 * - bg/backgroundColor
 * - opacity
 */
export const common = compose(
  color,
  space,
);

export const content = compose(
  overflowWrap,
  textOverflow,
  whiteSpace,
);
content.blacklist = [
  overflowWrap.blacklist,
  textOverflow.blacklist,
  whiteSpace.blacklist,
];

/**
 * ssLayout includes:
 * - display
 * - minHeight
 * - minWidth
 * - maxHeight
 * - maxWidth
 * - height
 * - width
 * - size (width and height together)
 * - overflow
 * - verticalAlign
 */
export const layout = ssLayout;

/**
 * ssPosition includes:
 * - position
 * - zIndex
 * - top
 * - right
 * - bottom
 * - left
 */
export const location = ssPosition;

/**
 * ssTypography includes:
 * - fontFamily
 * - fontSize
 * - fontStyle
 * - fontWeight
 * - lineHeight
 * - letterSpacing
 * - textAlign
 */
export const typography = compose(
  ssTypography,
  textStyle,
  textDecoration,
  textTransform,
);
typography.blacklist = [textDecoration.blacklist, textTransform.blacklist];
