import styled from 'styled-components';
import tag from 'clean-tag';

import { common, content, typography } from 'src/mixins/styled-system';
import extendTagBlacklist from 'src/utils/extend-tag-blacklist';

const Text = styled(tag).attrs(props => ({
  textStyle: `heading.${props.textSize}`,
}))`
  ${common};
  ${content};
  ${typography};
`;

Text.defaultProps = {
  blacklist: extendTagBlacklist([
    ...content.blacklist,
    ...typography.blacklist,
    'textSize',
  ]),
  margin: 0,
};

Text.displayName = 'Text';

export default Text;
