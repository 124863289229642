import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import {
  alignItems,
  display,
  flex,
  flexDirection,
  height,
  justifyContent,
  space,
} from 'styled-system';
import tag from 'clean-tag';

import { variants } from 'src/utils/theming';

const centeringProperty = variants('centering', {
  absolute: css`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  `,
  margin: css`
    margin: auto;
  `,
});

// min-width: 0; reason -
// https://css-tricks.com/flexbox-truncated-text/#article-header-id-3
const FlexAlignCenter = styled(tag)`
  ${alignItems};
  ${centeringProperty};
  ${display};
  ${flex};
  ${flexDirection};
  ${height};
  ${justifyContent};
  ${space};
  min-width: 0;
`;

FlexAlignCenter.propTypes = {
  ...alignItems.propTypes,
  /**
   * CENTERING OPTIONS:
   * absolute - Element is taken out of the flow and absolute positioned;
   * margin   - Element is positioned with margin but takes into account are
   *            siblings are present.
   */
  centering: PropTypes.string,
  ...display.propTypes,
  ...flex.propTypes,
  ...flexDirection.propTypes,
  ...height.propTypes,
  ...justifyContent.propTypes,
  ...space.propTypes,
};

FlexAlignCenter.defaultProps = {
  alignItems: 'center',
  centering: 'absolute',
  display: 'flex',
  flex: '0 1 auto',
  flexDirection: 'column',
};

export default FlexAlignCenter;
