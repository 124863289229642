import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import tag from 'clean-tag';
import { darken, lighten, transparentize, transitions } from 'polished';
import themeGet from '@styled-system/theme-get';

import { borderRadius, space } from 'styled-system';
import { layout, location, typography } from 'src/mixins/styled-system';
import transition from 'src/mixins/css-transitions';
import extendTagBlacklist from 'src/utils/extend-tag-blacklist';
import { variants } from 'src/utils/theming';

const variant = variants('variant', {
  normal: css`
    background-color: ${props => themeGet(`colors.${props.bg}`)(props)};
    color: ${props => themeGet(`colors.${props.color}`)(props)};

    &:hover:not(:disabled) {
      background-color: ${props =>
        lighten(0.15, themeGet(`colors.${props.bg}`)(props))};
    }

    &:active:not(:disabled) {
      background-color: ${props =>
        darken(0.15, themeGet(`colors.${props.bg}`)(props))};
    }
  `,
  flat: css`
    background-color: transparent;
    color: ${props => themeGet(`colors.${props.color}`)(props)};

    &:hover:not(:disabled) {
      color: ${props =>
        lighten(0.15, themeGet(`colors.${props.color}`)(props))};
    }

    &:active:not(:disabled) {
      color: ${props => darken(0.15, themeGet(`colors.${props.color}`)(props))};
    }
  `,
  outlined: css`
    background-color: transparent;
    box-shadow: inset 0 0 0 1px
      ${props => themeGet(`colors.${props.bg}`)(props)};
    color: ${props => themeGet(`colors.${props.color}`)(props)};

    &:hover:not(:disabled) {
      background-color: ${props =>
        transparentize(0.9, themeGet(`colors.${props.bg}`)(props))};
    }

    &:active:not(:disabled) {
      background-color: ${props =>
        transparentize(0.8, themeGet(`colors.${props.bg}`)(props))};
    }
  `,
});

const Button = styled(tag.button)`
  ${borderRadius};
  ${space};
  ${layout};
  ${location};
  ${typography};
  ${variant};
  ${transitions(
    transition({ property: 'color' }),
    transition({ property: 'background-color' }),
    transition({ property: 'box-shadow' }),
  )};
  border: none;
  cursor: pointer;

  :disabled {
    cursor: not-allowed;
    filter: opacity(45%);
  }
`;

Button.propTypes = {
  variant: PropTypes.oneOf(['normal', 'flat', 'outlined']),
};

// order matters for overriding certain props (e.g. font weight)
Button.defaultProps = {
  blacklist: extendTagBlacklist(typography.blacklist),
  borderRadius: '2px',
  bg: 'primary',
  color: 'button.text',
  fontFamily: 'sansSerif',
  px: 4,
  py: 2,
  textDecoration: 'none',
  textStyle: 'body.regular',
  fontWeight: 'regular',
  lineHeight: '100%',
  variant: 'normal',
  width: undefined,
};

Button.displayName = 'Button';

export default Button;
