import styled from 'styled-components';

import { bodyTextStyle } from 'src/mixins/text-styles';
import HiddenInput from './RadioFieldHiddenInput';

const RadioFieldLabel = styled.label`
  ${bodyTextStyle};
  color: ${props => props.theme.colors.text};
  cursor: pointer;
  display: inline-block;
  overflow-wrap: normal;
  padding-left: ${props => 20 + props.theme.space[4]}px;
  position: relative;

  &::before {
    content: 'radio_button_unchecked';
    color: ${props => props.theme.colors.gray['400']};
    font-family: 'Material Icons', serif;
    font-feature-settings: 'liga';
    display: inline-block;
    font-size: 20px;
    left: 0;
    position: absolute;
  }

  ${HiddenInput /* sc-sel */}:checked + &::before {
    content: 'radio_button_checked';
    color: ${props => props.theme.colors.primary};
  }

  ${HiddenInput /* sc-sel */}:disabled + & {
    color: ${props => props.theme.colors.gray.light};
    cursor: not-allowed;

    &::before {
      color: ${props => props.theme.colors.gray.light};
    }
  }
`;

export default RadioFieldLabel;
