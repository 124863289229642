import styled from 'styled-components';
import tag from 'clean-tag';
import { darken, lighten } from 'polished';
import themeGet from '@styled-system/theme-get';

import { common, layout, location, typography } from 'src/mixins/styled-system';
import transition from 'src/mixins/css-transitions';
import extendTagBlacklist from 'src/utils/extend-tag-blacklist';

function themeGetColor(props, propName = 'color') {
  return themeGet(`colors.${props[propName]}`)(props);
}

const Anchor = styled(tag.a)`
  ${common};
  ${layout};
  ${location};
  ${typography};
  cursor: pointer;
  transition: ${transition({ property: 'color' })};

  &:hover {
    color: ${props => lighten(0.15, themeGetColor(props))};
    text-decoration: underline;
  }

  &:active {
    color: ${props => darken(0.15, themeGetColor(props))};
  }
`;

// order matters for overriding certain props (e.g. font weight)
Anchor.defaultProps = {
  blacklist: extendTagBlacklist(typography.blacklist),
  color: 'primary',
  fontFamily: 'sansSerif',
  textStyle: 'body.regular',
  fontWeight: 'light',
  textDecoration: 'none',
};

Anchor.displayName = 'Anchor';

export default Anchor;
