import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const Portal = ({ children, style }) => {
  const isDocumentDefined = typeof document !== 'undefined';
  const parent = isDocumentDefined ? document.body : null;
  const [container] = useState(() => {
    if (isDocumentDefined) {
      const portal = document.createElement('div');
      portal.className = Portal.className;
      return portal;
    }

    // ssr
    return null;
  });

  // append portal container to parent (document.body or other)
  useEffect(() => {
    if (!container || !parent) return null;

    parent.appendChild(container);

    return () => {
      parent.removeChild(container);
    };
  }, [container, parent]);

  useEffect(() => {
    if (style) {
      container.style.cssText = Object.entries(style).reduce(
        (s, [propName, propValue]) => `${s}${propName}:${propValue};`,
        '',
      );
    }
  }, [container, style]);

  if (container) {
    const portal = createPortal(children, container);
    return portal;
  }

  // ssr
  return null;
};

Portal.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.oneOfType([PropTypes.object]),
};

Portal.defaultProps = {
  style: null,
};

Portal.className = 'prisma__portal';

export default Portal;
