import { css } from 'styled-components';
import { transparentize } from 'polished';

export const COLOR_SCHEMES_MAP = {
  black: css`
    color: ${props => props.theme.colors.white};
    background-color: ${props => transparentize(0.4, props.theme.colors.black)};
  `,
  white: css`
    color: ${props => props.theme.colors.black};
    background-color: ${props => transparentize(0.4, props.theme.colors.white)};
  `,
  error: css`
    color: ${props => props.theme.colors.white};
    background-color: ${props =>
      transparentize(0.4, props.theme.colors.danger)};
  `,
};
