import { createGlobalStyle } from 'styled-components';

const AppNormalize = createGlobalStyle`
  html {
    font-family: sans-serif;
    text-size-adjust: 100%;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  body {
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  :focus {
    outline-color: transparent;
    outline-style: none;
  }

  #root {
    overflow: auto;
    height: 100%;
  }

  #picter-app {
    height: 100%;
    overflow: hidden;
    width: 100%;
  }

  a {
    text-decoration: none;
  }
`;

export default AppNormalize;
