import themeGet from '@styled-system/theme-get';

export function generateMediaQuery(breakpointsKey) {
  return {
    lessThan(breakpointKey) {
      return props => {
        const breakpointValue = themeGet(`${breakpointsKey}.${breakpointKey}`)(
          props,
        );
        return `(max-width: ${breakpointValue})`;
      };
    },
    between(lowerBreakpointKey, upperBreakpointKey) {
      return props => {
        const lowBreakpointValue = themeGet(
          `${breakpointsKey}.${lowerBreakpointKey}`,
        )(props);
        const upperBreakpointValue = themeGet(
          `${breakpointsKey}.${upperBreakpointKey}`,
        )(props);
        return `(min-width: ${lowBreakpointValue}) and (max-width: ${upperBreakpointValue})`;
      };
    },
    greaterThan(breakpointKey) {
      return props => {
        const breakpointValue = themeGet(`${breakpointsKey}.${breakpointKey}`)(
          props,
        );
        return `(min-width: ${breakpointValue})`;
      };
    },
  };
}

export const mediaQuery = generateMediaQuery('deviceBreakpoints');
