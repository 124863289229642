import styled from 'styled-components';

import { typography } from 'src/mixins/styled-system';

const badgeColor = props => props.color || props.theme.colors.gray['600'];

const Badge = styled.span`
  ${typography};
  background-color: ${props => props.theme.colors.white};
  border-radius: 2px;
  border: solid 1px ${badgeColor};
  color: ${badgeColor};
  cursor: default;
  padding: 0.5px 2px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
`;

Badge.defaultProps = {
  textStyle: 'body.xsmall',
  fontFamily: 'sansSerif',
  fontSize: '10px',
  textTransform: 'uppercase',
};

export default Badge;
