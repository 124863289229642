import styled from 'styled-components';

import { colorProperty } from 'src/mixins/common-properties/color';
import IconGlyph from 'src/components/Icon/styles/IconGlyph';

const IconGroupStacked = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  z-index: 1;

  ${IconGlyph} {
    border-radius: 100%;
    box-shadow: 0 0 0 3px ${props => colorProperty(props, 'outline')};
  }

  > * {
    position: relative;
    z-index: 0;

    :hover {
      z-index: 1;
    }

    :not(:first-child) {
      margin-left: -${props => props.theme.space[1]}px;

      @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
        margin-left: -${props => props.theme.space[2]}px;
      }
    }
  }
`;

export default IconGroupStacked;
