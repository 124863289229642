import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { STATUS } from './constants';

function FontLoader({ config }) {
  const [, setStatus] = useState(STATUS.IDLE);

  useEffect(() => {
    // Dynamic import in componentDidMount in order to make it work with SSR (server-side rendering).
    const WebFont = require('webfontloader'); // eslint-disable-line global-require

    WebFont.load({
      loading: setStatus(STATUS.LOADING),
      active: setStatus(STATUS.ACTIVE),
      inactive: setStatus(STATUS.INACTIVE),
      ...config,
    });
  }, [config]);

  return null;
}

FontLoader.propTypes = {
  config: PropTypes.shape({}),
};

FontLoader.defaultProps = {
  config: {
    google: {
      families: ['Material Icons'],
    },
    custom: {
      families: ['CalibreWeb:n3,n4,n5', 'ITC-Charter:n4,n7,i4,i7'],
      urls: ['https://fonts.picter.com/fonts.css'],
    },
  },
};

export default FontLoader;
