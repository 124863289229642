import { Component } from 'react';
import PropTypes from 'prop-types';

import { STATUS, CONFIG } from './constants';
import { warning } from '../deprecation-log';

class WebFontLoader extends Component {
  state = {
    config: CONFIG,
    status: STATUS.IDLE,
  };

  componentDidMount() {
    warning(
      'WebFontLoader',
      '`WebFontLoader` has been deprecated. Use `FontLoader` instead.',
    );

    const { status } = this.state;
    if (status !== STATUS.IDLE) return;

    // Dynamic import in componentDidMount in order to make it work with SSR (server-side rendering).
    const WebFont = require('webfontloader'); // eslint-disable-line global-require
    this.loadFonts(WebFont);
  }

  handleLoading = () => {
    this.setState({ status: STATUS.LOADING });
  };

  handleActive = () => {
    this.setState({ status: STATUS.ACTIVE });
  };

  handleInactive = () => {
    this.setState({ status: STATUS.INACTIVE });
  };

  loadFonts = WebFont =>
    WebFont.load({
      loading: this.handleLoading,
      active: this.handleActive,
      inactive: this.handleInactive,
      ...this.state.config,
    });

  render() {
    return this.props.children;
  }
}

WebFontLoader.defaultProps = {
  children: null,
};

WebFontLoader.propTypes = {
  children: PropTypes.element,
};

export default WebFontLoader;
