import React, { useState } from 'react';
import PropTypes from 'prop-types';
import propTypes from '@styled-system/prop-types';

import Spinner from '../../Spinner';
import BannerContainer from '../styles/BannerContainer';
import BannerFrame from '../styles/BannerFrame';

const Banner = ({ width, height, src, Placeholder }) => {
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);

  return (
    <BannerContainer width={width} height={height} isLoaded={isIframeLoaded}>
      {!isIframeLoaded && <Placeholder />}
      <BannerFrame
        width={width}
        height={height}
        src={src}
        onLoad={() => setIsIframeLoaded(true)}
      />
    </BannerContainer>
  );
};

Banner.propTypes = {
  width: propTypes.layout.width,
  height: propTypes.layout.height,
  src: PropTypes.string.isRequired,
  Placeholder: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
};

Banner.defaultProps = {
  width: 1,
  height: 456,
  Placeholder: Spinner,
};

export default Banner;
