import styled from 'styled-components';
import PropTypes from 'prop-types';
import tag from 'clean-tag';
import { space } from 'styled-system';
import extendTagBlacklist from 'src/utils/extend-tag-blacklist';

const GeneralLayoutContent = styled(tag).attrs(({ main }) => ({
  blacklist: extendTagBlacklist(['limited', 'main']),
  is: main ? 'main' : 'section',
}))`
  ${space};
  flex: 1 0;
  ${props =>
    props.limited &&
    `
    max-width: 1200px;
    margin: auto;
  `};
`;

GeneralLayoutContent.propTypes = {
  main: PropTypes.bool,
  limited: PropTypes.bool,
  ...space.propTypes,
};

GeneralLayoutContent.defaultProps = {
  main: false,
  limited: false,
  py: [4, 6],
};

export default GeneralLayoutContent;
